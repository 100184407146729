import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import vuetify from '@/plugins/vuetify'
import '@/assets/css/main.css'
import '@/assets/css/main-sb.css'
import '@/assets/css/megaenu-v4.css'
import '@/assets/css/tracking-cross-sell.css'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
