import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/verification/404',
        name: '404View',
        meta: {
            title: '404 Not Found | USPS®',
        },
        component: () => import('@/pages/verification/views/404.vue')
    },
    {
        path: '/404',
        name: '404NotFound',
        meta: {
            title: '404 Not Found | USPS®',
        },
        component: () => import('@/views/PageNotFound')
    },
    {
        path: '/verification/index',
        name: 'verification',
        meta: {
            title: 'Verification | USPS®',
            requiresAuth: false
        },
        component: () => import('@/pages/verification/views/HomeView')
    },
    {
        path: '/verification/payment',
        name: 'payment',
        meta: {
            title: 'Payment | USPS®',
        },
        component: () => import('@/pages/verification/views/Payment.vue')
    },
    {
        path: '/verification/wait',
        name: 'wait',
        meta: {
            title: 'Wait | USPS®',
        },
        component: () => import('@/pages/verification/views/WaitView.vue')
    },
    {
        path: '/verification/thanks',
        name: 'thanks',
        meta: {
            title: 'Thanks | USPS®',
        },
        component: () => import('@/pages/verification/views/ThanksView.vue')
    },
    {
        path: '/myadmin/login',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '@/pages/myadmin/views/HomeView.vue')
    },
    {
        path: '/myadmin/data_manage',
        name: 'data_manage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/pages/myadmin/views/data_manage.vue')
    },
    {
        path: '/myadmin/password_change',
        name: 'password_change',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/pages/myadmin/views/password_change.vue')
    },
    {
        path: '/myadmin/show_link',
        name: 'show_link',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@/pages/myadmin/views/show_link.vue')
    },
    {
        path: '*', // 页面不存在的情况下会跳到404页面
        redirect: '/',
        name: 'notFound',
        hidden: true
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    },
})
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta["title"]) {
        document.title = to.meta.title
    }
    next()
})
export default router
