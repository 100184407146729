<template>
  <v-app>
    <v-main :style=background_color>
      <div v-if="($route.name !== 'show' && $route.name !== '404View')">
        <v-container style="padding-top: 40px;">
          <!--      物流单号标题-->
          <v-row style="margin-bottom: 30px">
            <v-col><h3>Tracking Number: <b style="color: #595959;font-weight: normal"><br v-show="newline_show">US9514901185421</b>
            </h3></v-col>
          </v-row>
          <!--      状态标题-->
          <v-row style="margin-bottom: 20px" v-show="show_title">
            <v-col><h3>Status: </h3></v-col>
          </v-row>
          <!--          手机端步骤条-->
          <v-row class="mt-0 mb-5" v-show="step_bar_mobile">
            <v-col>
              <div class="status_bar status_1">
                <div class="bar_third bar_third_1"><span :style="set_bar_1"></span></div>
                <div class="bar_third bar_third_2"><span :style="set_bar_2"></span></div>
                <div class="bar_third bar_third_3"><span :style="set_bar_3"></span></div>
              </div>
            </v-col>
          </v-row>
          <!--      状态-->
          <v-row style="margin-top: -20px">
            <v-col><h2>
              <strong
                  :style="status_color">{{ status_text }}</strong></h2></v-col>
          </v-row>
          <!--      状态文字-->
          <v-row style="margin-top:-40px;font-size:14px">
            <v-col v-show="status_red"><p class="mobile_p">You can schedule redelivery online by filling out your
              information, We ReDeliver for You!<br><br>
              Redeliveries can be scheduled online 24 hours a day, 7 days a week. For same-day Redelivery, make sure
              your request is submitted by 2 AM CST Monday-Saturday or your Redelivery will be scheduled for the next
              day.</p>
              <v-divider></v-divider>
            </v-col>
            <v-col v-show="status_green"><p style="margin-top:15px;font-size:14px;" class="mobile_p">For more
              information about claims,
              visit your local Post Office or our Web site at <a style="color: rgb(51, 51, 102)"
                                                                 href="https://www.usps.com/insuranceclaims/">https://www.usps.com/insuranceclaims/</a>.<br>
              The U.S.Postal Service values your buisness . We apologize for any inconvenience you may have experienced
              as a result of this matter .</p>
            </v-col>
          </v-row>
          <!--      步骤条-->
          <v-row v-show="show_title">
            <v-col>
              <div class="status_bar status_1">
                <div class="bar_third bar_third_1"><span :style="set_bar_1"></span></div>
                <div class="bar_third bar_third_2"><span :style="set_bar_2"></span></div>
                <div class="bar_third bar_third_3"><span :style="set_bar_3"></span></div>
              </div>
            </v-col>
          </v-row>
          <!--      状态文字-->
          <v-row class="mt-0" v-show="show_title">
            <v-col :offset="status_offset">
              <span class="text_explanation">Status Not Available</span>
            </v-col>
          </v-row>

        </v-container>
      </div>
      <router-view class="container-fluid"></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data: () => ({
    show_header: true
  }),
  computed: {
    newline_show() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
      }
      return false
    },
    show_title() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return false
      }
      return true
    },
    step_bar_mobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return true
      }
      return false
    },
    set_bar_1: {
      cache: false,
      get: function () {
        switch (this.$route.path) {
          case '/verification/payment':
            return {
              width: '100%'
            };
          case '/verification/wait':
            return {
              width: '100%'
            };
          case '/verification':
            return {
              width: '50%'
            };
          case '/verification/thanks':
            return {
              width: '100%'
            };
        }
        return false
      }
    },
    set_bar_2: {
      cache: false,
      get: function () {
        switch (this.$route.path) {
          case '/verification/payment':
            return {
              width: '50%'
            };
          case '/verification':
            return {
              width: '0%'
            };
          case '/verification/wait':
            return {
              width: '100%'
            };
          case '/verification/thanks':
            return {
              width: '100%'
            };
        }
        return false
      }
    },
    set_bar_3: {
      cache: false,
      get: function () {
        switch (this.$route.path) {
          case '/verification/payment':
            return {
              width: '0%'
            };
          case '/verification':
            return {
              width: '0%'
            };
          case '/verification/wait':
            return {
              width: '0%'
            };
          case '/verification/thanks':
            return {
              width: '50%'
            };
        }
        return false
      }
    },
    status_offset: {
      cache: false,
      get: function () {
        switch (this.$route.path) {
          case '/verification/payment':
            return 4
          case '/verification':
            return 0
          case '/verification/wait':
            return 5
          case '/verification/thanks':
            return 8
        }
        return 0
      }
    },
    status_color: {
      cancel: false,
      get: function () {
        if (this.$route.path === '/verification/thanks') {
          return 'color: green;font-weight: 700;font-size:22px;font-style: normal;font-family: \'HelveticaNeueW01\', Helvetica, Arial, sans-serif'
        } else {
          return 'color: #e71921;font-weight: 700;font-size:22px;font-style: normal;font-family: \'HelveticaNeueW01\', Helvetica, Arial, sans-serif'
        }
      }
    },
    status_text: {
      cache: false,
      get: function () {
        switch (this.$route.path) {
          case '/verification/thanks':
            return 'We have update your shipping address'
        }
        return 'We have issues with your shipping address'
      }
    },
    status_red: {
      cache: false,
      get: function () {
        switch (this.$route.path) {
          case '/verification/thanks':
            return false
        }
        return true
      }
    },
    status_green: {
      cache: false,
      get: function () {
        switch (this.$route.path) {
          case '/verification/thanks':
            return true
        }
        return false
      }
    },
    background_color: {
      cache: false,
      get: function () {
        if (this.$route.path === '/') {
          return "background-color: #00000"
        } else {
          return "background-color: #f7f7f7"
        }
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.show_header = !(this.$route.name !== 'show' && this.$route.name !== '404View');
      console.log(this.show_header)
    })
  },
};
</script>
<style>
.v-application--wrap {
  min-height: unset;
}

.status_bar .bar_third {
  position: relative;
  float: left;
  width: calc(33.33% - 5px);
  height: 15px;
  margin-right: 5px;
  transform: skew(-20deg);
  transform-origin: left bottom;
  background: #d8d8d8;
}

.status_bar .bar_third:first-child:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 100%;
  transform: skew(20deg);
  transform-origin: left bottom;
  background: #336;
}

.status_bar.status_1 .bar_third_1 span {
  width: 50%;
}

.status_1_half span {
  width: 50%;
}

.status_1_full span {
  width: 100%;
}

.status_bar .bar_third > span {
  display: block;
  position: absolute;
  width: 0;
  height: 100%;
  background: #336;
}

.text_explanation {
  margin-top: -20px;
  font-family: "HelveticaNeueW01", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #e71921;
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.mobile_p {
  font-family: 'HelveticaNeueW01', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  color: #595959;
  margin: 0;
  padding: 0;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

</style>
